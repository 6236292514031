<template>
  <v-container>
    <v-card class="mx-auto col-md-11">
      <v-card-title class="d-flex justify-space-around pt-0">
        <h1 class="font-weight-medium text-center">{{ title }}</h1>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="d-flex align-center" cols="12" xs sm="3" md="3">
            <span
              class="text-subtitle-1"
              :style="{ color: 'initial !important' }"
              >Código departamento:</span
            >
          </v-col>

          <v-col cols="12" xs sm="3" md="3">
            <v-text-field
              outlined
              dense
              hide-details
              type="number"
              v-model="item.ciud_cdgo_dep"
            ></v-text-field>
          </v-col>

          <v-col class="d-flex align-center" cols="12" xs sm="3" md="3">
            <span
              class="text-subtitle-1"
              :style="{ color: 'initial !important' }"
              >Código ciudad :</span
            >
          </v-col>

          <v-col cols="12" xs sm="3" md="3">
            <v-text-field
              outlined
              dense
              hide-details
              type="number"
              v-model="item.ciud_cdgo_mcp"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs sm="4" md="4">
            <v-text-field
              label="Region"
              outlined
              dense
              hide-details
              type="text"
              v-model="item.ciud_region"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs sm="4" md="4">
            <v-text-field
              label="Departamento"
              outlined
              dense
              hide-details
              type="text"
              v-model="item.ciud_desc_dep"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs sm="4" md="4">
            <v-text-field
              label="Ciudad"
              outlined
              dense
              hide-details
              type="text"
              v-model="item.ciud_desc_mcp"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-row class="d-flex justify-space-around text-center">
          <v-col class="pb-2">
            <v-btn
              class="mx-3 white--text blue-bootstrap"
              @click="checForm(true)"
            >
              {{ buttontext }}
            </v-btn>
          </v-col>
          <v-col class="pb-2">
            <v-btn
              class="mx-3 white--text green-bootstrap"
              @click="checForm(false)"
            >
              Guardar
            </v-btn>
          </v-col>
          <v-col class="pb-2">
            <v-btn class="mx-3 red-bootstrap white--text" @click="cancel">
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    add: true,
    title: "",
    buttontext: "",
    close: false,
    item: {},
  }),
  created() {
    let { id, add } = this.$route.query;
    this.add = String(add) == "true" ? true : false;

    if (this.add) {
      this.title = "Crear ciudad";
      this.buttontext = "Agregar ciudad";
    } else {
      this.title = "Actualizar ciudad";
      this.buttontext = "Actualizar ciudad";
      this.getItem(id);
    }
  },

  methods: {
    getItem(id = "") {
      let uri = "/ciudades/edit/" + id;

      this.axios
        .get(uri)
        .then((response) => {
          this.item = response.data;
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    checForm(close) {
      let data = this.item;

      if (!data.ciud_cdgo_dep) {
        this.$emit("msj", { text: "Código del departamento obligatorio" });
      } else if (!data.ciud_cdgo_mcp) {
        this.$emit("msj", { text: "Código de la ciudad es obligatorio" });
      } else if (!data.ciud_region) {
        this.$emit("msj", { text: "Region es obligatorio" });
      } else if (!data.ciud_desc_dep) {
        this.$emit("msj", { text: "Descripcion del departamento obligatorio" });
      } else if (!data.ciud_desc_mcp) {
        this.$emit("msj", { text: "Descripcion de la ciudad obligatorio" });
      } else {
        this.close = close;

        if (this.add) this.createCiudad();
        else this.updateCiudad();
      }
    },

    createCiudad() {
      this.axios
        .post("/ciudades/add", this.item)
        .then(() => {
          if (this.close) this.cancel();
          this.$emit("msj", { text: "Registro guardado", estado: "success" });
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    updateCiudad() {
      let uri = "/ciudades/update/" + this.item._id;

      this.axios
        .put(uri, this.item)
        .then(() => {
          if (this.close) this.cancel();
          this.$emit("msj", {
            text: "Registro actualizado",
            estado: "success",
          });
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    cancel() {
      this.$router.push({ name: "DisplayCiudad" });
    },
  },
};
</script>